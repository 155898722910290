import React from 'react';

const IconLogo = () => {
    return (
        <svg
            width="529"
            height="579"
            viewBox="0 0 529 579"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                className="logo-border"
                d="M283.612 16.3635L497.317 136.504C509.602 143.411 517.205 156.407 517.205 170.5V408.5C517.205 422.593 509.602 435.589 497.317 442.496L283.612 562.636C271.744 569.308 257.256 569.308 245.388 562.636L31.6833 442.496C19.3985 435.589 11.7953 422.593 11.7953 408.5V170.501C11.7953 156.407 19.3985 143.411 31.6833 136.504L245.388 16.3635C257.256 9.69161 271.744 9.6916 283.612 16.3635Z"
                stroke="black"
                strokeWidth="22"
            />
            <path
                className="logo-text"
                d="M98.1953 245.941V276H80.6172V190.688H113.898C120.305 190.688 125.93 191.859 130.773 194.203C135.656 196.547 139.406 199.887 142.023 204.223C144.641 208.52 145.949 213.422 145.949 218.93C145.949 227.289 143.078 233.891 137.336 238.734C131.633 243.539 123.723 245.941 113.605 245.941H98.1953ZM98.1953 231.703H113.898C118.547 231.703 122.082 230.609 124.504 228.422C126.965 226.234 128.195 223.109 128.195 219.047C128.195 214.867 126.965 211.488 124.504 208.91C122.043 206.332 118.645 205.004 114.309 204.926H98.1953V231.703Z"
                fill="black"
            />
            <path
                className="logo-text"
                d="M193 276C192.219 274.477 191.652 272.582 191.301 270.316C187.199 274.887 181.867 277.172 175.305 277.172C169.094 277.172 163.938 275.375 159.836 271.781C155.773 268.188 153.742 263.656 153.742 258.188C153.742 251.469 156.223 246.312 161.184 242.719C166.184 239.125 173.391 237.309 182.805 237.27H190.598V233.637C190.598 230.707 189.836 228.363 188.312 226.605C186.828 224.848 184.465 223.969 181.223 223.969C178.371 223.969 176.125 224.652 174.484 226.02C172.883 227.387 172.082 229.262 172.082 231.645H155.148C155.148 227.973 156.281 224.574 158.547 221.449C160.812 218.324 164.016 215.883 168.156 214.125C172.297 212.328 176.945 211.43 182.102 211.43C189.914 211.43 196.105 213.402 200.676 217.348C205.285 221.254 207.59 226.762 207.59 233.871V261.352C207.629 267.367 208.469 271.918 210.109 275.004V276H193ZM178.996 264.223C181.496 264.223 183.801 263.676 185.91 262.582C188.02 261.449 189.582 259.945 190.598 258.07V247.172H184.27C175.793 247.172 171.281 250.102 170.734 255.961L170.676 256.957C170.676 259.066 171.418 260.805 172.902 262.172C174.387 263.539 176.418 264.223 178.996 264.223Z"
                fill="black"
            />
            <path
                className="logo-text"
                d="M258.566 269.555C254.387 274.633 248.605 277.172 241.223 277.172C234.426 277.172 229.23 275.219 225.637 271.312C222.082 267.406 220.266 261.684 220.188 254.145V212.602H237.121V253.559C237.121 260.16 240.129 263.461 246.145 263.461C251.887 263.461 255.832 261.469 257.98 257.484V212.602H274.973V276H259.035L258.566 269.555Z"
                fill="black"
            />
            <path className="logo-text" d="M305.617 276H288.625V186H305.617V276Z" fill="black" />
            <path
                className="logo-text"
                d="M165.617 388V302.688H195.5C205.852 302.688 213.703 304.68 219.055 308.664C224.406 312.609 227.082 318.41 227.082 326.066C227.082 330.246 226.008 333.938 223.859 337.141C221.711 340.305 218.723 342.629 214.895 344.113C219.27 345.207 222.707 347.414 225.207 350.734C227.746 354.055 229.016 358.117 229.016 362.922C229.016 371.125 226.398 377.336 221.164 381.555C215.93 385.773 208.469 387.922 198.781 388H165.617ZM183.195 350.852V373.879H198.254C202.395 373.879 205.617 372.902 207.922 370.949C210.266 368.957 211.438 366.223 211.438 362.746C211.438 354.934 207.395 350.969 199.309 350.852H183.195ZM183.195 338.43H196.203C205.07 338.273 209.504 334.738 209.504 327.824C209.504 323.957 208.371 321.184 206.105 319.504C203.879 317.785 200.344 316.926 195.5 316.926H183.195V338.43Z"
                fill="black"
            />
            <path
                className="logo-text"
                d="M276.77 340.48C274.465 340.168 272.434 340.012 270.676 340.012C264.27 340.012 260.07 342.18 258.078 346.516V388H241.145V324.602H257.141L257.609 332.16C261.008 326.34 265.715 323.43 271.73 323.43C273.605 323.43 275.363 323.684 277.004 324.191L276.77 340.48Z"
                fill="black"
            />
            <path
                className="logo-text"
                d="M319.953 388C319.172 386.477 318.605 384.582 318.254 382.316C314.152 386.887 308.82 389.172 302.258 389.172C296.047 389.172 290.891 387.375 286.789 383.781C282.727 380.188 280.695 375.656 280.695 370.188C280.695 363.469 283.176 358.312 288.137 354.719C293.137 351.125 300.344 349.309 309.758 349.27H317.551V345.637C317.551 342.707 316.789 340.363 315.266 338.605C313.781 336.848 311.418 335.969 308.176 335.969C305.324 335.969 303.078 336.652 301.438 338.02C299.836 339.387 299.035 341.262 299.035 343.645H282.102C282.102 339.973 283.234 336.574 285.5 333.449C287.766 330.324 290.969 327.883 295.109 326.125C299.25 324.328 303.898 323.43 309.055 323.43C316.867 323.43 323.059 325.402 327.629 329.348C332.238 333.254 334.543 338.762 334.543 345.871V373.352C334.582 379.367 335.422 383.918 337.062 387.004V388H319.953ZM305.949 376.223C308.449 376.223 310.754 375.676 312.863 374.582C314.973 373.449 316.535 371.945 317.551 370.07V359.172H311.223C302.746 359.172 298.234 362.102 297.688 367.961L297.629 368.957C297.629 371.066 298.371 372.805 299.855 374.172C301.34 375.539 303.371 376.223 305.949 376.223Z"
                fill="black"
            />
            <path
                className="logo-text"
                d="M344.914 355.832C344.914 345.949 347.121 338.078 351.535 332.219C355.988 326.359 362.062 323.43 369.758 323.43C375.93 323.43 381.027 325.734 385.051 330.344V298H402.043V388H386.75L385.93 381.262C381.711 386.535 376.281 389.172 369.641 389.172C362.18 389.172 356.184 386.242 351.652 380.383C347.16 374.484 344.914 366.301 344.914 355.832ZM361.848 357.062C361.848 363 362.883 367.551 364.953 370.715C367.023 373.879 370.031 375.461 373.977 375.461C379.211 375.461 382.902 373.254 385.051 368.84V343.82C382.941 339.406 379.289 337.199 374.094 337.199C365.93 337.199 361.848 343.82 361.848 357.062Z"
                fill="black"
            />
            <path
                className="logo-text"
                d="M438.84 364.035L450.559 324.602H468.723L443.234 397.844L441.828 401.184C438.039 409.465 431.789 413.605 423.078 413.605C420.617 413.605 418.117 413.234 415.578 412.492V399.66L418.156 399.719C421.359 399.719 423.742 399.23 425.305 398.254C426.906 397.277 428.156 395.656 429.055 393.391L431.047 388.176L408.84 324.602H427.062L438.84 364.035Z"
                fill="black"
            />
        </svg>
    );
};

export default IconLogo;
// import React from 'react';

// const IconLogo = () => {
//     return (
//         <svg
//             width="500"
//             height="500"
//             viewBox="0 0 500 500"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg">
//             <path
//                 className="logo-border"
//                 d="M15.2997 245.5L128.753 48.9936C130.36 46.209 133.332 44.4936 136.547 44.4936H363.453C366.668 44.4936 369.639 46.209 371.247 48.9936L484.7 245.5C486.308 248.285 486.308 251.715 484.7 254.5L371.247 451.006C369.639 453.791 366.668 455.506 363.453 455.506H136.547C133.332 455.506 130.36 453.791 128.753 451.006L15.2997 254.5C13.692 251.715 13.692 248.285 15.2997 245.5Z"
//                 stroke="black"
//                 strokeWidth="22"
//             />
//             <path
//                 className="logo-text"
//                 d="M153 308.364H177.602V270.636H198.284C225.045 270.636 240.614 254.67 240.614 231.432C240.614 208.307 225.33 192 198.909 192H153V308.364ZM177.602 250.92V212.114H194.193C208.398 212.114 215.273 219.841 215.273 231.432C215.273 242.966 208.398 250.92 194.307 250.92H177.602Z"
//                 fill="black"
//             />
//             <path
//                 className="logo-text"
//                 d="M256.594 308.364H306.31C332.446 308.364 346.366 294.727 346.366 276.205C346.366 258.989 334.037 248.591 320.798 247.966V246.83C332.901 244.102 341.821 235.523 341.821 221.83C341.821 204.386 328.866 192 303.185 192H256.594V308.364ZM281.196 288.25V257.341H301.821C313.639 257.341 320.969 264.159 320.969 273.761C320.969 282.511 315.003 288.25 301.253 288.25H281.196ZM281.196 240.693V211.886H299.889C310.798 211.886 316.991 217.511 316.991 225.92C316.991 235.125 309.491 240.693 299.435 240.693H281.196Z"
//                 fill="black"
//             />
//         </svg>
//     );
// };

// export default IconLogo;
